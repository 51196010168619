/* Add these custom styles in your src/index.css file */

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(223, 223, 223, 1) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(223, 223, 223, 1);
  border-radius: 0.25rem;
  border: 0.1rem solid transparent;
  background-clip: content-box;
}

.group:hover .custom-scrollbar::-webkit-scrollbar {
  opacity: 1;
}

/* HORIZONTAL SCROLLBAR ONLY */

.custom-h-scrollbar::-webkit-scrollbar {
  width: 0rem !important;
  height: 1.4rem !important;
}

.custom-h-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(223, 223, 223);
  border: 0.5rem solid transparent;
  background-clip: content-box;
  border-radius: 10rem;
}

/* VERTICAL SCROLLBAR ONLY */

.custom-v-scrollbar::-webkit-scrollbar {
  width: 1.4rem !important;
  height: 0rem !important;
}

.custom-v-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(223, 223, 223);
  border: 0.5rem solid transparent;
  background-clip: content-box;
  border-radius: 10rem;
}

/* -------------------------------------------------- ANT DESIGN -------------------------------------------------- */
.ant-btn-primary {
  background-color: #7d55c7;
  &:hover {
    background-color: #30106a !important;
  }
}

/* default button  */
/* .ant-btn-default {
  &:hover {
    border: 1px solid #d9d9d9 !important;
    color: #7d55c7 !important;
  }
} */

.ant-btn-default:hover {
  border: 1px solid #d9d9d9 !important;
  color: #7d55c7 !important;
}

/* Ant Design Checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7d55c7 !important;
  border-color: #7d55c7 !important;
}

.ant-checkbox-checked::after {
  border-color: #7d55c7;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #a78dca !important;
}
/* ------------- MENU ---------------- */
.ant-menu-title-content {
  color: #7d55c7 !important;
  /* font-weight: 600; */
  border: none !important;
}

.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid #7d55c7 !important;
}

.ant-menu-item-selected::after {
  content: none;
  border-bottom: transparent !important;
}

.ant-menu-item:hover::after {
  content: none;
  border-bottom: transparent !important;
}

/* ----------------------------- */

/* Add this CSS to your stylesheet or in a styled-components block */

/* Add this CSS to your stylesheet */

.custom-menu.ant-menu-inline {
  background-color: #f6edff;
  border-radius: 0 !important;
}

.custom-menu .ant-menu-item {
  background-color: transparent !important;
  border: none !important;
  padding-left: 24px !important;
}

.custom-menu .ant-menu-submenu-title {
  background-color: #f6edff !important;
}

.custom-menu .ant-menu-item-selected {
  background-color: #e0d1ff !important;
  border-right: 3px solid #722ed1 !important;
  border-radius: 0 !important;
}

.custom-menu .ant-menu-submenu-open .ant-menu-submenu-title {
  background-color: #e0d1ff !important;
}

.custom-menu .ant-menu-item::after {
  border-right: none !important;
}

/* ---- Switch ---- */

.ant-switch-checked {
  background: #722ed1 !important;
}
