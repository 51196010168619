/* styles.css */
.mask-gradient {
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 5%,
    black 30%,
    black 70%
  );
  mask-image: linear-gradient(to top, transparent 5%, black 30%, black 70%);
}
