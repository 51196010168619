.loader-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 150px; /* Height for 3 items */
  position: relative;
}

.loader-container::before,
.loader-container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 20%; /* Adjust the height for a stronger or subtler effect */
  z-index: 1;
  pointer-events: none; /* Ensures interaction with content below */
}

.loader-container::before {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(111, 66, 193, 1),
    rgba(255, 255, 255, 0)
  );
}

.loader-container::after {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(111, 66, 193, 1),
    rgba(255, 255, 255, 0)
  );
}

.loader-animation {
  animation: flowUp 60s linear infinite;
  display: flex;
  flex-direction: column;
}

@keyframes flowUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-600px); /* Total height to move, adjust as needed */
  }
}

.loader-step {
  height: 50px; /* Height of a single item, adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .loader-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 150px;
}

.loader-animation {
  animation: flowUp 36s linear infinite;
  display: flex;
  flex-direction: column;
}

@keyframes flowUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-600px);
  }
}

.loader-step {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
