.wrapper {
  background-color: #f0f0f0; /* Example background color */
}

.text-container {
  white-space: nowrap;
  padding: 2rem;
  position: relative;
  display: flex;
  gap: 1rem; /* Adjust the gap between words */
}

.text-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px; /* Width of the fade effect */
  background: linear-gradient(to right, rgba(240, 240, 240, 0), #f0f0f0 90%);
}

.fade-text {
  font-size: 2rem; /* Adjust font size as needed */
  animation: slide 6s infinite linear;
  white-space: pre; /* Keeps spacing and line breaks */
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
