.my-quill .ql-toolbar {
  border: 0px solid red;
  border-radius: 4px;
}

.my-quill .ql-snow {
  border: 0px solid red;
  border-radius: 4px;
}

.ql-editor {
  font-size: 20px;
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  opacity: 0;
}
